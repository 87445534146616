<template>
  <a-card>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
      title="电费记录"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <div slot="money_render_4" slot-scope="text">
        {{ (text / 100).toFixed(4) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleWatch(record)">查看</a>
        </template>
      </span>
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'

import { electricity_meter_record_list } from '@/api/electricity'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      key: 1,
      loading: false,
      // 创建窗口控制
      visible: false,
      mdl: {},
      editmdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: { },
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '电表编号',
          dataIndex: 'meter_number',
          ellipsis: true
        },
        {
          title: '批次',
          dataIndex: 'batch',
          ellipsis: true
        },
        {
          title: '读表数',
          ellipsis: true,
          dataIndex: 'value'
        },
        {
          title: '上期读表数',
          ellipsis: true,
          dataIndex: 'last_value'
        },
        {
          title: '单价',
          ellipsis: true,
          dataIndex: 'price',
          scopedSlots: { customRender: 'money_render_4' }
        },
        {
          title: '总价',
          ellipsis: true,
          dataIndex: 'amount',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '时间',
          width: 200,
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return electricity_meter_record_list({ bill_id: this.$route.params.id })
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleWatch (record) {
      console.log(record)
      this.$router.push({
        path: '/electricity_meter/' + record.meter_id + '/',
        query: { number: record.meter_number }
      })
    }
  }
}
</script>
